// old colors
$beta-blue: #172b4d;
$gray: #C4C4C4;

// Blues
$hover-fill: #deebff4d;
$pixie: #deebff;
$light-hover-fill: #F5F9FF;
$light-green: #F8FFFC;
$warning-bg: #FFFBE6;

$black: #000000;
$hover-fill: #F5F9FF;
$hover: #F5F9FF;



// new design system colors

// blue
$bluebell: #65A8FF;
$blue-hover: #F5F9FF; // same $light-hover-fill, $hover-fill, $hover
$breeze: #B3D4FF;
$cobalt: #2C72DC;
$deep-ocean: #0747a6;
$pacific: #0052cc;
$pixie-dust: #E8F1FF; // there is other pixie
$sky: #448CEA;

// turquoise
$aqua: #97E2EA;
$capri: #06B1C1;
$caribbean: #61DAE8;
$celestial: #E4FDFF;
$mist: #F3FEFF;
$morocco: #007D88;
$peacock: #0096A6;
$toothpaste: #C1F3F8;

// green
$cloverleaf: #A7EEB2;
$green-apple: #3EBC5D;
$green-tea: #7FD993;
$kale: #006644;
$mint: #D3FFDD;
$pine: #2DB463;
$slime: #008746;
$seafoam: #EFFFF8;
$seaspray: #F9FFFC;

// orange
$cantaloupe: #FFA939;
$cheezit: #FF9736;
$deep-orange: #D47400;
$golden-state: #FFC25F;
$orange-crush: #F18300;
$peach: #FFD99A;
$popsicle: #FFF0D7;

// yellow
$lemon: #FFE500;
$sticky-note: #FBF5E0;
$sunshine: #FFF279;
$cream: #FFFAEE;

// red
$blush: #fff8f8;
$cinnamon: #DE2E21;
$critical: #900000;
$pie-pink: #ECD3FF;
$pomegranate: #bf2600;
$poppy: #FF2B2B;
$red-dirt: #de350b;
$rosie: #FFF0F0;
$salmon: #FF7070;
$smoothie: #FF9999;
$sunburn: #FFC1C1;

// purple
$deep-purple: #260978;
$grape-juice: #6554c0;
$lavender: #7572E9;
$lavender-ice: #CFCCFE;
$pale-purple: #DDE2FF;
$pie-purple: #733FC8;
$pollen-dust: #FAF8FF;
$soap: #E7EAFF;
$wirsteria: #B1ACFF;

// pink
$bubblegum: #FFDBEC;
$taffy: #FF4FA3;
$unicorn: #FC9DD8;
$watermelon: #DC1374;
$wine: #AE0054;

// dark neutrals
$orca: #344563;
$slate: #091E42;
$smoke: #5E6C84;
$squid-ink: #061530;

// mid neutrals
$blanche: #A5ADBA;
$fog: #7A869A;
$ghost: #97A0AF;
$sentinel: #BFC6D0;

// light neutrals
$dust: #F4F5F7;
$jolly: #E9EBF0;
$karl: #CCD3DD;
$white: #FFFFFF;





$third-party: #9536fc;
$internal: #1c65f8;
$external: #167b52;


$sensitive: #e96c5a;



// TD COLORS

$black: #000;
$near-black: #262626;
$white: #fff;
$gray-light: #fafafa;
$medium-gray: #bfbfbf;

$turquoise: #40a9ff;
$blue-500: #1890ff;

$border-color: #e8e8e8;
$border-gray-1: #d9d9d9;

$button-bg: #0b2a41;
$button-text: #c4c4c4;

$button-bg-highlighted: #124062;
$button-text-highlighted: $white;

$payload-background: #f0f4f5;
$payload-value: #fd988a;
$payload-border-bottom: #dedede;

$menu-background: #0b2a41;

$chart-event-red: #ff4d4f;
$chart-traces-green: #b9f0b4;

$indicator-gray: #aaa;
$indicator-green: #61c993;

$badge-blue: #5aa9d5;
$nav-bg: #001936;

// Transparent colors
$transparent-white: rgba(255, 255, 255, .4);
$transparent-whitest: rgba(255, 255, 255, .95);

/* Gray shades */

$gray-50: #F8FAFB;
$gray-100: #E3E8EE;
$gray-200: #C1C9D2;
$gray-300: #A3ACB9;
$gray-400: #8792A2;
$gray-500: #4F566B;
$gray-600: #697386;
$gray-700: #3C4257;
$gray-800: #2A2F45;
$gray-900: #1A1F36;

/* Red shades */

$red-50: #FFF8F5;
$red-100: #FDE2DD;
$red-200: #FBB5B2;
$red-300: #FA8389;
$red-400: #ED5F74;
$red-500: #CD3D64;
$red-600: #A41C4E;
$red-700: #80143F;
$red-800: #5E1039;
$red-900: #420828;

/* Coral shades */

$coral-50: #FFFAEE;
$coral-100: #FEE3C0;
$coral-200: #F8B886;
$coral-300: #F5925E;
$coral-400: #E56F4A;
$coral-500: #C44C34;
$coral-600: #9E2F28;
$coral-700: #7E1E23;
$coral-800: #5D161B;
$coral-900: #420E11;

/* Jade shades */

$jade-50: #EFFFED;
$jade-100: #CBF4C9;
$jade-200: #85D996;
$jade-300: #33C27F;
$jade-400: #1EA672;
$jade-500: #09825D;
$jade-600: #0E6245;
$jade-700: #0D4B3B;
$jade-800: #0B3733;
$jade-900: #082429;

/* Green shades */

$green-50: #F9FCE6;
$green-100: #EBF5BF;
$green-200: #C9DE7E;
$green-300: #A3CF4E;
$green-400: #7BBA49;
$green-500: #449437;
$green-600: #21732C;
$green-700: #0E5927;
$green-800: #073B1E;
$green-900: #002614;

/* Purple shades */

$purple-50: #FFF8FE;
$purple-100: #FCE0F6;
$purple-200: #F0B4E4;
$purple-300: #E28DDC;
$purple-400: #C96ED0;
$purple-500: #A450B5;
$purple-600: #7B3997;
$purple-700: #5B2B80;
$purple-800: #401D6A;
$purple-900: #2D0F55;

/* Blue shades */

$blue-50: #F5FBFF;
$blue-100: #D6ECFF;
$blue-200: #A4CDFE;
$blue-300: #7DABF8;
$blue-400: #6C8EEF;
$blue-500: #556CD6;
$blue-600: #3D4EAC;
$blue-700: #2F3D89;
$blue-800: #212D63;
$blue-900: #131F41;

/* Text */

$text-50: rgba(26, 31, 54, .1);
$text-100: rgba(26, 31, 54, .2);
$text-200: rgba(26, 31, 54, .3);
$text-300: rgba(26, 31, 54, .4);
$text-400: rgba(26, 31, 54, .5);
$text-500: rgba(26, 31, 54, .6);
$text-600: rgba(26, 31, 54, .7);
$text-700: rgba(26, 31, 54, .8);
$text-800: rgba(26, 31, 54, .9);
$text-900: #1A1F36;


/* Yellow shades */

$yellow-50: #FAF9E3;
$yellow-100: #F5F3B8;
$yellow-200: #EDE97E;
$yellow-300: #E0D346;
$yellow-400: #D4B417;
$yellow-500: #B38A14;
$yellow-600: #8C600E;
$yellow-700: #70460A;
$yellow-800: #4A2B06;
$yellow-900: #381F04;

/* Cyan shades */

$cyan-50: #EDFDFD;
$cyan-100: #C4F1F9;
$cyan-200: #7FD3ED;
$cyan-300: #4DB7E8;
$cyan-400: #3A97D4;
$cyan-500: #067AB8;
$cyan-600: #075996;
$cyan-700: #06457A;
$cyan-800: #093353;
$cyan-900: #042235;

/* Indigo shades */

$indigo-50: #F8F9FE;
$indigo-100: #E6E6FC;
$indigo-200: #C7C2EA;
$indigo-300: #B0A1E1;
$indigo-400: #9C82DB;
$indigo-500: #8260C3;
$indigo-600: #61469B;
$indigo-700: #4B3480;
$indigo-800: #352465;
$indigo-900: #1F184E;

/* Orange shades */

$orange-50: #FCF9E9;
$orange-100: #F8E5B9;
$orange-200: #EFC078;
$orange-300: #E5993E;
$orange-400: #D97917;
$orange-500: #BB5504;
$orange-600: #983705;
$orange-700: #762B0B;
$orange-800: #571F0D;
$orange-900: #3A1607;


// -------------- NEW COLORS --------------------------------

$midnightBlue: #090B28;
$ghostWhite: #f8f8f8;
$charcoalGray: #747474;
$lightGray: #EAEAEA;
$bluePrimary: #4B52FB;
$grayDark: #565656;
$grayDarker: #262626;
$softGray: #d9d9d9;